import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Link } from 'react-router-dom';
import userId from '../../../../../../../shared/helpers/userId';
import ViaDate from '../../../../../../components/library/viaDate';
import Paragraph from '../../../../../../components/library/paragraph';
import Button from '../../../../../../components/library/button';
import ViaModal from '../../../../../../components/library/viaModal';
import PlanForm from '../../../../forms/planForm';
import ReinstatePlan from '../../../../modals/reinstatePlan';
import JoinedGroup from '../../../../modals/joinedGroup';
import RejoinGroupPlan from '../../../../modals/rejoinGroupPlan';
import { addUserToPlan } from '../../../../../../../actions/plans';
import AssociateProgramToPlan from '../../../../modals/associateProgramToPlan';
import RemoveProgramFromPlan from '../../../../modals/removeProgramFromPlan';
import NameAndIdentityModal from '../../../../../../forms/pages/traveler/modals/NameAndIdentity.modal';
import sOvDetails from './ovRightNav.scss';
import PencilIcon from '../../../../../../travelerProfile/components/icons/PencilIcon';
import AddPlanFormsToPlanModal from '../../../../../../../components/AddPlanFormsToPlanModal';
import AddIcon from '../../../../../../travelerProfile/components/icons/AddIcon';
import UpdatePlanFormsToPlanModal from '../../../../../../../components/UpdatePlanFormsToPlanModal';
import DeletePlanFormsToPlanModal from '../../../../../../../components/DeletePlanFormsToPlanModal';
import { getPlan } from '../../../../../../../actions/plans';
export const enhance = compose(withStyles(sOvDetails));

function OvRightNav(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.profile.currentUser);
  const permissions = useSelector(state => state.profile.permissions);
  const selectedPlan = useSelector(state => state.plans.plan);
  const userPlanJoin = useSelector(state => state.plans.addPatchUserPlan);
  const clientFeatureList = useSelector(state => state.profile.clientFeatureList);
  const customAliases = useSelector(state => state.profile.customAliases);
  const [showReinstatePlan, setShowReinstatePlan] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [showJoinedGroup, setShowJoinedGroup] = useState(false);
  const [showRejoinGroup, setShowRejoinGroup] = useState(false);
  const [joinSaving, setJoinSaving] = useState(false);
  const [showAssociateToPlan, setShowAssociateToPlan] = useState(false);
  const [showRemoveProgram, setShowRemoveProgram] = useState(false);
  const [allLocs, setAllLocs] = useState('');
  const { profile, readonly } = useSelector(state => state.travelerProfile);
  const [showNameIdentityModal, setShowNameIdentityModal] = useState(false);
  const [showNameIdentityModalButton, setShowNameIdentityModalButton] = useState(false);
  const [showAddPlanFormModal, setShowAddPlanFormModal] = useState(false);
  const [showUpdatePlanFormModal, setShowUpdatePlanFormModal] = useState(false);
  const [showDeletePlanFormModal, setShowDeletePlanFormModal] = useState(false);
  const [selectedFormTemplateID, setSelectedFormTemplateID] = useState("");
  const [selectedFormDeadline, setSelectedFormDeadline] = useState("");
  const [selectedTemplateName, setSelectedTemplateName] = useState("");

  useEffect(()=>{
    if((!profile?.first_name && !readonly.includes('first_name') ) || ( !profile?.last_name && !readonly.includes('last_name'))) {
      setShowNameIdentityModalButton(true);
    }
  },[profile, readonly])

  let programLink = '';
  if (
    !!selectedPlan.plan &&
    !!selectedPlan.plan.attributes.program_range &&
    !!selectedPlan.plan.attributes.program_range.program_id
  ) {
    if (props.as === 'admin') {
      programLink = `/client/programs/${selectedPlan.plan.attributes.program_range.program_id}`;
    } else {
      programLink = `/traveler/programs/${selectedPlan.plan.attributes.program_range.program_id}`;
    }
  }

  useEffect(
    () => {
      if (!!selectedPlan && !!selectedPlan.plan) {
        let locsString = '';
        if (selectedPlan.plan.attributes.locations && selectedPlan.plan.attributes.locations.length > 0) {
          selectedPlan.plan.attributes.locations.map(loc => {

            let locationStr = "";

            if(loc.locality && loc.country_common_name){
              locationStr = loc.locality + ', ' + loc.country_common_name;
            }
            else{
              locationStr = loc.formatted_address;
            }
            locsString = locsString + locationStr + '; ';
          });
          locsString = locsString.slice(0, -2);
        }
        setAllLocs(locsString);
      }
    },
    [selectedPlan],
  );

  const joinGroup = () => {
    const values = {
      plans_user: {
        user_id: userId,
        group_lead: false,
        removed: false,
      },
    };
    dispatch(addUserToPlan(selectedPlan.plan.id, values));
  };

  useEffect(
    () => {
      if (userPlanJoin) {
        if (userPlanJoin.loading) {
          setJoinSaving(true);
        }
        if (joinSaving && !!userPlanJoin.user) {
          setJoinSaving(false);
          setShowJoinedGroup(true);
        }
        if (joinSaving && !!userPlanJoin.error) {
          setJoinSaving(false);
          alert(userPlanJoin.error);
        }
      }
    },
    [userPlanJoin],
  );

  return (
    <div className={sOvDetails['ov-right-nav']}>
      {!!selectedPlan &&
        !!selectedPlan.plan && (
          <>
            <div className={sOvDetails['ov-right-nav-dates']}>
              <Paragraph>DATES:</Paragraph>
              <Paragraph>
                <ViaDate date={selectedPlan.plan.attributes.start_date} /> -{' '}
                <ViaDate date={selectedPlan.plan.attributes.end_date} />
              </Paragraph>
            </div>

            <div className={sOvDetails['ov-right-nav-locations']}>
              <Paragraph>LOCATIONS:</Paragraph>
              <Paragraph>{allLocs}</Paragraph>
            </div>

            <div className={sOvDetails['ov-right-nav-type']}>
              <Paragraph>PLAN TYPE:</Paragraph>
              <Paragraph>{selectedPlan.plan.attributes.plan_type_name}</Paragraph>
            </div>

            <div className={sOvDetails['ov-right-nav-type']}>
              <Paragraph>PLAN CREATED BY:</Paragraph>
              <Paragraph>{selectedPlan.plan.attributes?.owner?.first_name} {selectedPlan.plan.attributes?.owner?.last_name}</Paragraph>
            </div>



            <div className={sOvDetails['ov-right-nav-type']}>
              <Paragraph>PLAN STATUS:</Paragraph>
              <Paragraph>{selectedPlan.plan.attributes.plan_status_name}</Paragraph>
            </div>

            <div className={sOvDetails['ov-right-nav-type']}>
              <Paragraph>REGISTRATION STATUS:</Paragraph>
              <Paragraph>{selectedPlan.plan.attributes.plan_registration_status_name}</Paragraph>
            </div>

            {props.as === 'admin' && (
              <div className={sOvDetails['ov-right-nav-type']}>
                <Paragraph>AVAILABILITY:</Paragraph>
                <Paragraph>{selectedPlan.plan.attributes.private ? 'Private' : 'Public'}</Paragraph>
              </div>
            )}

            {props.as === 'admin' &&
            selectedPlan.plan.attributes.group &&
            !selectedPlan.plan.attributes.program_range &&
            permissions.travel_plans_settings.includes('create_or_edit_travel_plan_and_itinerary') &&
            clientFeatureList.legacy ? (
              <div className={sOvDetails['ov-right-nav-program']}>
                <Paragraph>ASSOCIATED {customAliases.alias_program.toUpperCase()}:</Paragraph>
                <div
                  className={sOvDetails['ov-right-nav-associate-program']}
                  onClick={() => setShowAssociateToPlan(true)}
                >
                  <Paragraph>Associate a {customAliases.alias_program}</Paragraph>
                </div>
              </div>
            ) : (
              <div className={sOvDetails['ov-right-nav-program']}>
                {selectedPlan.plan.attributes.group &&
                selectedPlan.plan.attributes.program_range &&
                clientFeatureList.legacy ? (
                  <div>
                    <Paragraph>ASSOCIATED {customAliases.alias_program.toUpperCase()}:</Paragraph>
                    <Link to={programLink} target="_blank">
                      {selectedPlan.plan.attributes.program_range
                        ? selectedPlan.plan.attributes.program_range.program_title
                        : null}
                    </Link>
                    {props.as === 'admin' &&
                    permissions.travel_plans_settings.includes('create_or_edit_travel_plan_and_itinerary') ? (
                      <div
                        className={sOvDetails['ov-right-nav-associate-program']}
                        onClick={() => setShowRemoveProgram(true)}
                      >
                        <Paragraph>Remove {customAliases.alias_program}</Paragraph>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            )}

            <div className={sOvDetails['ov-right-nav-program']}>
              { selectedPlan.plan.attributes?.plan_form_groupings?.length>0 &&
                <Paragraph>ASSOCIATED PLAN FORMS:</Paragraph>
              }
              {
                selectedPlan.plan.attributes?.plan_form_groupings?.map((plan)=>(
                  <div className={sOvDetails['planList']}>
                    <div className={sOvDetails['planInfoContainer']}>
                      <div className={sOvDetails['planTitle']}>{plan.template_name}</div>
                      <div className={sOvDetails['planDeadline']}>{plan.deadline ? moment(plan.deadline).format('MMMM D, YYYY') : ""}</div>
                    </div>
                    {props.as === 'admin' &&
                      <div className={sOvDetails['planAction']}>
                        <a href="#" onClick={(e)=>{
                          e.preventDefault();
                          setSelectedFormTemplateID(plan.id)
                          setSelectedFormDeadline(plan.deadline)
                          setSelectedTemplateName(plan.template_name)
                          setShowUpdatePlanFormModal(true);
                        }}><PencilIcon /></a>
                      </div>
                    }
                  </div>
                ))
              }
              {props.as === 'admin' &&
                <div className={sOvDetails['buttonContainer']}>
                  <button
                    className={sOvDetails['addFormBotton']}
                    onClick={()=>setShowAddPlanFormModal(true)}
                  >
                    <AddIcon />
                    <span>Add Form(s)</span>
                  </button>
                </div>
              }
            </div>
            <div className={sOvDetails['ov-right-nav-action']}>
              {!selectedPlan.plan.attributes.removed &&
                ((selectedPlan.plan.attributes.group && props.as !== 'traveler') ||
                  !selectedPlan.plan.attributes.group) && (
                  <Button
                    display="primary"
                    kind="solid"
                    onClick={() => setShowCreate(true)}
                    disabled={
                      currentUser['client_user_role?']
                        ? !permissions.travel_plans_settings?.includes('create_or_edit_travel_plan_and_itinerary')
                        : selectedPlan.plan.attributes.group
                          ? true
                          : false
                    }
                  >
                    Edit {selectedPlan.plan.attributes.group ? 'Group' : props.as === 'traveler' ? 'My' : 'Solo'} Plan
                  </Button>
                )}

              {selectedPlan.plan.attributes.removed &&
                (selectedPlan.plan.attributes.group && props.as !== 'traveler') && (
                  <Button
                    display="primary"
                    kind="solid"
                    onClick={() => setShowReinstatePlan(true)}
                    disabled={
                      currentUser['client_user_role?'] &&
                      !permissions.travel_plans_settings.includes('create_or_edit_travel_plan_and_itinerary')
                    }
                  >
                    Reinstate Plan
                  </Button>
                )}

              {selectedPlan.plan.attributes.removed &&
                (!selectedPlan.plan.attributes.group && props.as !== 'admin') && (
                  <Button display="primary" kind="solid" onClick={() => setShowReinstatePlan(true)}>
                    REINSTATE PLAN
                  </Button>
                )}

              {!selectedPlan.plan.attributes.removed &&
                selectedPlan.plan.attributes.joinable &&
                (selectedPlan.plan.attributes.group &&
                  !selectedPlan.plan.attributes.group_removed &&
                  selectedPlan.plan.attributes.plan_registration_status_id === 1 &&
                  props.as !== 'admin') && <>
                  {showNameIdentityModalButton ?
                    <Button display="primary" kind="solid" onClick={() => setShowNameIdentityModal(true)} loading={joinSaving}>
                      JOIN PLAN
                    </Button>
                    :
                    <Button display="primary" kind="solid" onClick={() => joinGroup()} loading={joinSaving}>
                      JOIN PLAN
                    </Button>
                  }
                  </>
                }

              {selectedPlan.plan.attributes.group &&
                !selectedPlan.plan.attributes.group_removed &&
                selectedPlan.plan.attributes.plan_registration_status_id !== 1 &&
                props.as !== 'admin' && <dvi>This plan is not currently accepting registrations.</dvi>}

              {selectedPlan.plan.attributes.removed &&
                (selectedPlan.plan.attributes.group &&
                  selectedPlan.plan.attributes.joinable &&
                  !selectedPlan.plan.attributes.group_removed &&
                  props.as !== 'admin') && (
                  <Button display="primary" kind="solid" onClick={() => setShowRejoinGroup(true)} loading={joinSaving}>
                    REJOIN PLAN
                  </Button>
                )}
            </div>
            <ViaModal
              showing={showCreate}
              onClose={() => setShowCreate(false)}
              headerText={`Edit ${
                selectedPlan.plan.attributes.group ? 'GROUP' : props.as === 'traveler' ? 'MY' : 'SOLO'
              } Plan`}
            >
              <PlanForm
                onClose={() => setShowCreate(false)}
                plan={selectedPlan.plan}
                isGroup={!!selectedPlan.plan.attributes.group}
                as={props.as}
              />
            </ViaModal>
            <ReinstatePlan
              show={showReinstatePlan}
              onClose={() => setShowReinstatePlan(false)}
              planId={selectedPlan.plan.id}
              group={selectedPlan.plan.attributes.group}
            />
            <JoinedGroup
              show={showJoinedGroup}
              onClose={() => setShowJoinedGroup(false)}
              planId={selectedPlan.plan.id}
            />
            <RejoinGroupPlan
              show={showRejoinGroup}
              onClose={() => setShowRejoinGroup(false)}
              planId={selectedPlan.plan.id}
            />
            <AssociateProgramToPlan
              show={showAssociateToPlan}
              onClose={() => setShowAssociateToPlan(false)}
              planId={selectedPlan.plan.id}
            />
            <RemoveProgramFromPlan
              show={showRemoveProgram}
              onClose={() => setShowRemoveProgram(false)}
              planId={selectedPlan.plan.id}
            />
            {showNameIdentityModal && (
              <NameAndIdentityModal
                handleApplicationSubmit={() => {
                    setShowNameIdentityModal(false);
                    joinGroup();
                  }
                }
                show={showNameIdentityModal}
                onClose={() => {
                    setShowNameIdentityModal(false);
                  }
                }
              />
            )}
            {showAddPlanFormModal && (
              <AddPlanFormsToPlanModal
                show={showAddPlanFormModal}
                action="planOverView"
                handleSubmitPlan={()=>{
                  dispatch(getPlan(selectedPlan.plan.id));
                }}
                handleClose={() => {
                  setShowAddPlanFormModal(false);
                }}
                planIds={[selectedPlan.plan.id]}
              />
            )}
            {showUpdatePlanFormModal && selectedFormTemplateID && (
              <UpdatePlanFormsToPlanModal
                action="planOverView"
                handleUpdatePlan={()=>{
                  dispatch(getPlan(selectedPlan.plan.id));
                }}
                show={showUpdatePlanFormModal}
                handleClose={() => {
                  setShowUpdatePlanFormModal(false);
                }}
                templateID={selectedFormTemplateID}
                deadline={selectedFormDeadline}
                handleDeleteModal={()=>{
                  setShowUpdatePlanFormModal(false);
                  setShowDeletePlanFormModal(true);
                }}

              />
            )}
            {showDeletePlanFormModal && selectedFormTemplateID && (
              <DeletePlanFormsToPlanModal
                action="planOverView"
                handleUpdatePlan={()=>{
                  dispatch(getPlan(selectedPlan.plan.id));
                }}
                show={showDeletePlanFormModal}
                onClose={() => {
                  setShowDeletePlanFormModal(false);
                }}
                templateName={selectedTemplateName}
                templateID={selectedFormTemplateID}
              />
            )}
          </>
        )}
    </div>
  );
}

OvRightNav.propTypes = {
  as: propTypes.oneOf(['traveler', 'admin', 'leader']).isRequired,
};

export default enhance(OvRightNav);
