import { orderBy } from 'lodash';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import NoResults from './NoResults';
import NoPrograms from './NoPrograms';
import ProgramCard from './ProgramCard';
import RedoArrowIcon from '../icons/RedoArrowIcon';

import { breakpoints } from '../../utils/breakpoints';
import { buttonFocus } from '../../utils/shared/focusStyles';
import getFilteredPrograms from '../../utils/program-dashboard/getFilteredPrograms';
import FavoriteProgramModal from '../../../viaGlobal/components/modals/favoriteProgramModal';
import userId from '../../../../shared/helpers/userId';
import { toggleFavorite } from '../../../../actions/programDashboardActions';
import { getCurrentUser } from '../../actions/travelerProfileActions';
import { getUserDetails } from '../../../../actions/profileActions';
import { TOGGLE_PROGRAM_FAVORITE } from '../../../../actions/types';
import LoadingScreen from '../../../../styledComponents/styles/LoadingScreen';

const Grid = styled.div`
  // display: grid;
  // grid-template-columns: repeat(3, minmax(0, 1fr));
  // column-gap: 1.6875rem;
  // row-gap: 1.875rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.875rem;
  margin: 0;
  padding: 0 1.75rem 2.3125rem 1.75rem;

  @media ${breakpoints.desktop} {
    margin-top: 1.875rem;
    padding: 0;
  }
`;

const RetakeLink = styled(Link)`
  display: flex;
  align-self: flex-end;
  align-items: center;
  gap: 0.5625rem;
  margin-right: 1.8275rem;
  margin-bottom: 1.875rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #446372;
  letter-spacing: 0.06em;
  text-decoration-line: underline;
  line-height: 120%;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #446372;
    text-decoration-line: none;
  }

  @media ${breakpoints.desktop} {
    margin-top: 1.0625rem;
    margin-bottom: 0;
    margin-right: 0;
  }

  @media (max-width: 767px) {
    /* Your CSS rules for small devices */
    justify-content: center;
    margin-right: 0rem;
  }

  ${buttonFocus};
`;

const ErrorMessage = styled.p`
  margin-top: 1rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #373737;
  letter-spacing: 0.0025em;
  line-height: 120%;
`;

export default function ProgramCardsGrid() {
  const dispatch = useDispatch();
  const { matchComplete, showMatch } = useSelector(state => state.travelerProfile);
  const favoriteDisclaimerUpdated = useSelector(state => state.programBrochure.update_favorite_disclaimer);
  const { user_role } = useSelector(state => state.profile);
  const currentOrganization = useSelector(state => state.currentOrganization);
  const enable_active_term_name_filtering = currentOrganization.info.enable_active_term_name_filtering;
  
  
  const programs = useSelector(state => state.programDashboard.programs);
  //const [localPrograms, setLocalPrograms] = useState([]);
  const { programsError, sortProperties, sortDirections, searchTerm, selectedFilters, programsLoading } = useSelector(
    state => state.programDashboard,
  );
  const {
    customAliases: { alias_program: programAlias, alias_programs: programsAlias },
  } = useSelector(state => state.currentOrganization);

  const [sortedPrograms, setSortedPrograms] = useState([]);
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [favoriteProgramId, setFavoriteProgramId] = useState('');
  const [isFavoriteFlag, setIsFavoriteFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (userId && favoriteDisclaimerUpdated) {
      dispatch(getCurrentUser(userId));
    }
  }, [favoriteDisclaimerUpdated]);

  // useEffect(() => {
  //   setLocalPrograms(programs?.filter(p => p?.attributes?.private?.toLowerCase() !== 'yes'));
  // }, [programs]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetails(userId));
    }
  }, []);

  useEffect(() => {
    if (programs?.length > 0) {
      setIsLoading(true);
      const localPrograms = programs?.filter(p => p?.attributes?.private?.toLowerCase() !== 'yes')    
      if(localPrograms?.length > 0) {
        const mappedPrograms = localPrograms.map(program => program?.attributes);
        const filteredPrograms = getFilteredPrograms(mappedPrograms, searchTerm, selectedFilters,enable_active_term_name_filtering);
        const filteredProgramsUpdated = filteredPrograms.map((filteredProgram)=>{
          return {
          ...filteredProgram,
            program_name_sorted: filteredProgram.program_name.trim().toUpperCase()
          };
        })	
        const sortedProperties = sortProperties.map(value=>{
          if (value === 'program_name') {
            return "program_name_sorted";
          }
          return value;
        })
        setSortedPrograms(orderBy(filteredProgramsUpdated, sortedProperties, sortDirections));
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [sortProperties, sortDirections, searchTerm, selectedFilters, programs]);

  const handleToggleFavoriteProgram = (programId, isFavorite) => {
    setFavoriteProgramId(programId);
    setIsFavoriteFlag(isFavorite);
    setShowFavoriteModal(!showFavoriteModal);
  };

  const submitFavourite = () => {
    const isFavDisAccepted = true;
    dispatch(toggleFavorite(userId, favoriteProgramId, isFavoriteFlag, isFavDisAccepted));

    dispatch({
      type: TOGGLE_PROGRAM_FAVORITE,
      payload: {
        programId: favoriteProgramId,
        flag: true,
      },
    });

    //Add update user API here
  };

  if(programsLoading){
    return <>
      <div className='text-center'>
        Loading programs...
      </div>
    </>
  }

  return (
    <>   
      {programsError && (
        <ErrorMessage>
          There was an issue with loading {programsAlias.toLowerCase()}. Please try again later.
        </ErrorMessage>
      )}
      {!programsError && programs?.length === 0 && 
        <NoPrograms />
      }
      
      {!programsError && programs?.length > 0 && (
        <>
          {user_role === 'traveler' && showMatch && matchComplete && (
            <RetakeLink to="/traveler/program-match">
              <RedoArrowIcon />
              <span>Retake {programAlias} Match</span>
            </RetakeLink>
          )}

          {isLoading ? (
            <>
              <LoadingScreen displayText="Loading..."  width={"100%"} />
            </>  
            ) : sortedPrograms?.length > 0 ? (
                <Grid role="list">
                  {sortedPrograms.map(program => (
                    <ProgramCard
                      key={`program-${program.program_id}`}
                      program={program}
                      toggleFavoriteProgram={handleToggleFavoriteProgram}
                    />
                  ))}
                </Grid>
              ) : (
              <NoResults />
          )}
        </>
      )}

      <FavoriteProgramModal
        show={showFavoriteModal}
        onSubmit={() => submitFavourite()}
        onClose={() => {
          setShowFavoriteModal(!showFavoriteModal);
        }}
      />
    </>
  );
}
